@import "../../index.scss";

.project-pillars {

    &--container {
        position: absolute;
        top: $coreMenuHeight;
        height: calc(100% - $coreMenuHeight);
        width: 100%;
    }

    &--video-container {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;

        > video {
            position: absolute;
            height: 100%;
            top: 0; left: 50%;
            transform: translateX(-50%);
        }
    }

    &--copy-container {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        
        height: 100%;
        padding: 0 18%;
        background: $darkColour;

        &__atmosphere {
            @extend .project-pillars--copy-container;
            padding: 0 15%;
        }
    }

    &--title {
        display: block;
        @include font-primary;
        font-size: 56px;
        text-align: center;
    }

    &--statement {
        display: block;
        @include font-secondary-light;
        font-size: 16px;
        text-align: center;
    }
}

.highlights {
    &--title {
        display: block;
        @include font-primary;
        margin-bottom: 1em;
        text-align: center;
    }

    &--statement {
        display: block;
        @include font-primary;
        margin-bottom: 0.8em;
        font-size: 16px;
        text-align: center;
    }

    &--statement2 {
        display: block;
        @include font-primary;
        margin-bottom: 0.8em;
        font-size: 16px;
        text-align: center;
    }
}

.highlights--transform-container {
    flex-wrap: wrap;
    max-width: 100%;

    position: absolute;
    top: calc($coreMenuHeight + $secondaryMenuHeight) !important;
    height: calc(100% - ($coreMenuHeight + $secondaryMenuHeight)) !important;
}

.highlights--split-page.highlights--arrival .highlights--copy-container {
    padding: 0;
    
    .highlights--title {
        font-size: 52px;
    }
}

.highlights--split-page .highlights {

    &--copy-container {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        
        height: 100%;
        padding: 25%;
        background: $bkgColour;
        background: $bkgGradient;
    }

    &--statments-container {
        padding-bottom: 60px;
    }

    &--title.title {
        font-size: 32px;
        margin-bottom: 0.8em;
    }

    &--statement.statement {
        text-transform: uppercase;
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 0.5em;
    }

    &--image {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
    }

    &--layer-image-container {
        position: relative;
        width: 100;
        height: 100%;

        > img {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translateY(-50%);
        }
    }

    &--button-container {
        position: relative;
        width: 100%;
        padding-bottom: 100px;
        text-align: center;
    }

    &--button {
        display: inline-block;
        position: relative;
        padding: 15px 30px;

        @include font-primary;
        font-size: 18px;
        color: inherit;
        text-transform: uppercase;
        letter-spacing: .3em;

        opacity: 0.65;

        &::after {
            content: '';
            position: absolute;
            width: 0px;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            opacity: 0;
            border-bottom: 1px solid currentColor;

            transition: all 0.3s ease-in-out;
        }

        &__active {
            @extend .highlights--button;
            opacity: 1;

            &::after {
                opacity: 1;
                width: 150px;
            }
        }

        &:hover {
            @extend .highlights--button__active;
        }

    }

}

.building-highlights {
    display: flex;
    flex-direction: row;
    align-content: center;
    background: $bkgColour;
    background: $bkgGradient;
    position: relative;

    > * {
        z-index: 1;
    }

    &:before {
        content: '';
        position: absolute;
        width: 50%;
        height: 100%;
        background: $bkgColour;
        background: $bkgGradient;
        background-size: 200% 100%;
        background-position: right;
        transform: scaleX(-1);
        z-index: 0;
    }

    & &--highlight {
        width: 33.3%;
        color: white;
        padding: 50px 0;
    }

    .highlights {
        &--title {
            display: block;
            @include font-primary;
            font-size: 56px;
            // margin-bottom: 0.3em;
            text-align: center;
        }
    
        &--statments-container {
            padding-bottom: 60px;
        }
    
        &--statement {
            display: block;
            @include font-primary;
            margin-bottom: 0.05em;
            font-size: 18px;
            text-align: center;
        }

        &--statement2 {
            display: block;
            @include font-primary;
            margin-bottom: 0.8em;
            font-size: 18px;
            text-align: center;
        }
    }
    
    .highlights--statement {
        @include font-secondary;
    }

    .highlights--statement2 {
        @include font-secondary;
    }
}